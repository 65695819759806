import eventtypes from "@content/advanced/images/event-types.png";
import eventbell from "@content/advanced/images/event-bell.png";
import interfaceevents from "@content/tutorial/images/interface-events.png";

import { PageLayout, Section } from "@components";

import "@styles/Page.scss";

export default function FeatEvents() {
  return (
    <PageLayout title="Events" roles={["admin"]}>
      <Section id="types-of-events" title="Types of Events">
        <p>
          Events in Dotter allow for marking important stages of patient
          participation in the study, from their inclusion to their exit from
          the protocol.
          <br />
          Each form template can optionally be associated with an event.
        </p>
        <img src={eventtypes} alt="Types of Events" className="centered" />

        <p>
          The events that can be associated are:
          <li>
            <b>Inclusion / Exclusion</b>: defines the form used to complete the
            criteria for inclusion, non-inclusion, and/or exclusion of patients.
            If no errors are found when completing the form, the patient is
            directly included in the protocol; conversely, any unexpected value
            will lead to exclusion, preserving a record of the screening stage.
          </li>
          <li>
            <b>Completed Protocol</b>: defines the final form of the study,
            after which the patient is considered to have successfully completed
            all stages of the clinical investigation.
          </li>
          <li>
            <b>Adverse Event</b>: can be associated with one or more form
            templates, to mark each report of a serious (or non-serious) adverse
            event for different patients throughout the study.
          </li>
          <li>
            <b>Study Exit</b>: defines a form after which the patient exits the
            study prematurely, whether due to withdrawal of consent, a serious
            adverse event, etc.
          </li>
        </p>
        <h2 id="uniqueness-of-forms-with-event">
          Uniqueness of Forms with Event
        </h2>
        <p>
          Associating a form with an event makes it "Unique," meaning that its
          state upon saving is <b>final</b> and cannot be edited by the
          investigator.
        </p>
        <img src={eventbell} alt="Event Bell" className="centered" />
        <p>
          Events associated with a study can be easily identified within the
          Form Templates by the presence of a <b>bell</b>. When the template is
          associated with a step in the Study Synopsis, it inherits the bell as
          well.
        </p>
        <h2 id="recording-events-live">Recording Events "Live"</h2>
        <p>
          When a form associated with an event is saved by one of the
          investigators for a given patient, the event is automatically recorded
          in the study's database. All events can be found in the{" "}
          <b>Event List</b>, a table present in the <b>Live</b> tab of Dotter.
        </p>
        <img src={interfaceevents} alt="Event List" className="centered" />
        <p>
          It is also possible to notify certain users by email of the occurrence
          of a specific type or all events of the study. Please contact an
          administrator to configure the notifications.
        </p>
      </Section>
    </PageLayout>
  );
}
